<template>
  <div>
    <image-crop-upload
               field="file"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="value"
               :width="width"
               :height="height"
               :url="url"
               :img-format="imgFormat"></image-crop-upload>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageCropUpload from 'vue-image-crop-upload';

  export default {
    name: "ciicImgUpload",
    components:{
      imageCropUpload
    },
    props:{
      value: {
        type: Boolean,
        'default': false
      },
      width: {
        type: Number,
        'default': 200
      },
      height: {
        type: Number,
        'default': 200
      },
      url: {
        type: String,
        'default': process.env.VUE_APP_BASE_API + '/file'
      },
      imgFormat: {
        type: String,
        'default': 'png'
      },
    },
    computed: {
      headers() {
        return {
          Authorization: `Bearer ${this.accessToken}`,
        };
      },
      ...mapGetters(['accessToken'])
    },
    watch: {
      // 子组件修改value后同步到父组件
      value(newValue) {
        this.$emit('input', newValue);
      }
    },
    data() {
      return {}
    },
    created(){
    },
    methods: {
      /**
       * upload success 上传成功回调
       *
       * [param] jsonData   服务器返回数据，已进行json转码
       * [param] field
       */
      cropUploadSuccess(jsonData, field){
        this.$emit('handleUploadSuccess',jsonData,field)
      },

      /**
       * crop success 裁剪成功回调
       *
       * [param] imgDataUrl 裁剪后的图片
       * [param] field
       */
      cropSuccess(imgDataUrl, field){
        this.$emit('handleCropSuccess',imgDataUrl,field)
      },

      /**
       * upload fail 上传失败回调
       *
       * [param] status    server api return error status, like 500
       * [param] field
       */
      cropUploadFail(status, field){
        this.$emit('handleUploadField',status,field)
      },
    }
  }


</script>

<style>

</style>
